<template>
  <div class="root-box">
    <div class="header-box">
      <div class="tab tab-video" @click="selectTab(0)">
        <div class="tab-text">关注</div>
        <div class="tab-indicator" v-show="tabIndex === 0"></div>
      </div>
      <div class="tab tab-product" @click="selectTab(1)">
        <div class="tab-text">粉丝</div>
        <div class="tab-indicator" v-show="tabIndex === 1"></div>
      </div>
    </div>
    <div class="content-box">
      <div class="content" v-if="tabIndex === 0">
        <div class="no-data" v-if="attentionList.length === 0">
          <span>暂无数据</span>
        </div>
        <FriendItem
          :v-if="attentionList.length > 0"
          class="attention-item"
          v-for="(item, index) in attentionList"
          :key="index"
          :feedUserInfo="item"
        >
        </FriendItem>
      </div>
      <div class="content" v-if="tabIndex === 1">
        <div class="no-data" v-if="fansList.length === 0">
          <span>暂无数据</span>
        </div>

        <FriendItem
          class="fans-item"
          v-for="(item, index) in fansList"
          :key="index"
          :feedUserInfo="item"
        >
        </FriendItem>
      </div>
    </div>
  </div>
</template>
<script>
import FriendItem from "@/components/user/FriendItem";
export default {
  components: {
    FriendItem,
  },
  data() {
    return {
      tabIndex: 0,
      attentionList: [],
      fansList: [],
      userId: 0,
      stUserInfo: null,
    };
  },
  async mounted() {
    if (this.$route.query.id) {
      this.userId = this.$route.query.id;
    }
    this.stUserInfo = await this.getUserInfo();
    console.log(JSON.stringify(this.stUserInfo));
    this.requestData();
    this.listenBack();
    this.$nextTick(()=>{
      let _index = parseInt(this.$route.query.index);
      if(_index){
        this.tabIndex = _index;
      }
    });
  },
  methods: {
    selectTab(index) {
      this.tabIndex = index;
    },
    async requestData() {
      await this.getAttenttionList(1);
      await this.getAttenttionList(2);
    },
    listenBack() {
      lisenNative("webviewShow", async () => {
        let userInfo = await this.getUserInfo();
        if (
          (!this.stUserInfo || this.stUserInfo.AID <= 0) &&
          userInfo &&
          userInfo.AID > 0
        ) {
          this.stUserInfo = userInfo;
          this.requestData();
        }
      });
    },
    getUserInfo() {
      return new Promise((resolve, reject) => {
        beacon.getAccountInfo({}, (accountInfo) => {
          console.log("accountInfo",accountInfo);
          resolve(accountInfo);
        });
      });
    },
    async getAttenttionList(type) {
      const stUserInfo = {
        vGUID: [],
        sDUA: "",
        iAccountId: this.stUserInfo ? this.stUserInfo.AID : 0, // 灯塔互动APP登陆后才有的id
      };

      const params = {
        followerOrFans: type,
        userId: this.userId,
      };
      
      const data = await this.API.appSlider.getAttenttionList(type,this.userId);
      console.log(data);
      if (data.status === 200) {
        if (data.data.data.length == 0) {
          return;
        }
        if (type === 1) {
          this.attentionList = data.data.data;
        } else if (type === 2) {
          this.fansList = data.data.data;
        }
      } else {
        console.error(data);
      }
    },
  },
};
</script>
<style lang="css" scoped>
.root-box {
  background: #f7f7f7;
}
img{
  touch-callout: none;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;
}
.header-box {
  position: -webkit-sticky;
  position: sticky;
  height: 40px;
  top: 0;
  background: #f7f7f7;
}
.tab-box {
  height: 40px;
  width: 100%;
  background: white;
}
.tab {
  text-align: center;
  height: 40px;
  line-height: 40px;
}

.tab-video {
  width: 50%;
  height: 100%;
  float: left;
}

.tab-product {
  width: 50%;
  float: right;
}

.tab-text {
  float: left;
  width: 100%;
}

.tab-indicator {
  width: 26px;
  height: 3px;
  background: #ff8f1f;
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
  margin-top: 36px;
}
.content-box {
  width: 100%;
}
.content-box .content {
  width: 100%;
}

.no-data {
  height: 200px;
  width: 100%;
  line-height: 200px;
  text-align: center;
  color: #2525259a;
}
</style>