<template >
  <div class="friend-box" @click="toUserPage">
    <div class="avatar-box">
      <img
        alt="头像"
        class="avatar"
        :src="feedUserInfo.sFaceUrl"
        v-if="feedUserInfo.sFaceUrl"
      />
      <!--  v-if="feedUserInfo.eUserType === 2" -->
      <div class="vip" v-if="feedUserInfo.eUserType === 2">
        <img src="/assets/images/interact_video/user_icon_v.png" alt="vip" />
      </div>
    </div>
    <div class="nickname">
      <span>{{ feedUserInfo.sNickName }}</span>
    </div>
  </div>
</template>
<script>
import { debounce} from "@/utils/utils.js";
export default {
  props: ["feedUserInfo"],
  methods: {
    toUserPage:debounce(function(){
      this.$toNewPage("/user",{id:this.feedUserInfo.iAccountId},true);
    }),
  },
};
</script>
<style lang="css" scoped>
img{
  touch-callout: none;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;
}
.friend-box {
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  height: 66px;
  width: 100%;
  padding: 0 16px;
  align-items: center;
  background: white;
}
.avatar-box {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background-color: rgba(170, 170, 170, 0.363);
}
.avatar-box .avatar {
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.nickname {
  line-height: 66px;
  height: 66px;
  padding-left: 14px;
}
.nickname span {
  font-size: 16px;
  color: #252525;
}
.vip{ 
  float: right;
  width: 15px;
  height: 15px;
}
.vip img{ 
  width: 14px;
  height: 14px;
  margin-top: 35px;
}
</style>